import React from "react";
import ReactDOM from "react-dom";
import PrivateRoute from "components/Route/PrivateRoute.jsx";
import history from './history';
import "assets/scss/material-dashboard-pro-react.scss?v=1.7.0";
import { Provider } from 'react-redux';
import { lazy, Suspense  } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from 'store/index.jsx';
import LoadingAlert from 'components/CustomSweetAlert/LoadingAlert.jsx';

import Login from 'views/Login/Login.jsx';

//Student
import Student                             from 'views/Student/Index.jsx';
const WelcomePackage                      = lazy( () => import ('views/WelcomePackage/Index.jsx') );
const NewStudent                          = lazy( () => import( 'views/Student/New/New.jsx') );
const ShowStudent                         = lazy( () => import( 'views/Student/Show/Show.jsx') );
const EditStudent                         = lazy( () => import( 'views/Student/Edit/Edit.jsx') );
const PreEvaluationStudent                = lazy( () => import( 'views/Student/PreEvaluation/New.jsx') );
const PostEvaluationStudent               = lazy( () => import( 'views/Student/PostEvaluation/New.jsx') );
const StudentChangePassword               = lazy( () => import( 'views/Student/ChangePassword/ChangePassword.jsx') );

//Ambassador
const Ambassador                          = lazy(() => import( 'views/Ambassador/Index.jsx') );
const ShowAmbassador                      = lazy(() => import( 'views/Ambassador/Show/Show.jsx') );
const EditAmbassador                      = lazy(() => import( 'views/Ambassador/Edit/Edit.jsx') );
const NewAmbassador                       = lazy(() => import( 'views/Ambassador/New/New.jsx') );
const AmbassadorChangePassword            = lazy(() => import( 'views/Ambassador/ChangePassword/ChangePassword.jsx') );
const AmbassadorStudent                   = lazy(() => import( 'views/AmbassadorStudent/Index.jsx') );
const AmbassadorStudentAssignMentor       = lazy(() => import( 'views/AmbassadorStudent/AssignMentor/Index.jsx') );
const AmbassadorStudentConfirmMentor      = lazy(() => import( 'views/AmbassadorStudent/AssignMentor/Confirm.jsx') );

//general
const ReactTable                          = lazy(() => import( 'views/Tables/ReactTables.jsx') );
const Reports                             = lazy(() => import( 'views/Reports/Index.jsx') );
const AuthLayout                          = lazy(() => import( 'layouts/Auth.jsx') );
const RtlLayout                           = lazy(() => import( 'layouts/RTL.jsx') );

//Login
const Age                                 = lazy(() => import( 'views/Age/Age.jsx') );
const App                                 = lazy(() => import( 'views/Age/App.jsx') );

//Group
const Group                               = lazy(() => import( 'views/Group/Index.jsx') );
const ShowGroup                           = lazy(() => import( 'views/Group/Show/Show.jsx') );
const EditGroup                           = lazy(() => import( 'views/Group/Edit/Edit.jsx') );
const NewGroup                            = lazy(() => import( 'views/Group/New/New.jsx') );
const GroupAmbassadors                    = lazy(() => import( 'views/Group/New/ListAmbassador/Index.jsx') );
const ProjectProgress                     = lazy(() => import( 'views/Group/Progress/Index.jsx') );
const UploadImage                         = lazy(() => import( 'views/Group/Participants/Index.jsx') );
const StudentGroup                        = lazy(() => import( 'views/Group/Participants/Index.jsx') );

//Course
const CourseNew                           = lazy(() => import( 'views/Course/New/New.jsx') );

//Programmbs
const IndexProgramMbs                     = lazy(() => import( 'views/Programmbs/Index.jsx') );
const ProgramMbs                          = lazy(() => import( 'views/Programmbs/Show/Show.jsx') );
const NewProgramMbs                       = lazy(() => import( 'views/Programmbs/New/Show.jsx') );
const NewPlanProgramMbs                   = lazy(() => import( 'views/Programmbs/New/NewPlan.jsx') );
const NewProductProgramMbs                = lazy(() => import( 'views/Programmbs/New/NewProduct.jsx') );
const NewProcessProgramMbs                = lazy(() => import( 'views/Programmbs/New/NewProcess.jsx') );
const NewPriceProgramMbs                  = lazy(() => import( 'views/Programmbs/New/NewPrice.jsx') );
const NewPromotionProgramMbs              = lazy(() => import( 'views/Programmbs/New/NewPromotion.jsx') );
const NewPaperworkProgramMbs              = lazy(() => import( 'views/Programmbs/New/NewPaperwork.jsx') );
const NewQualityProgramMbs                = lazy(() => import( 'views/Programmbs/New/NewQuality.jsx') );
const NewServiceProgramMbs                = lazy(() => import( 'views/Programmbs/New/NewService.jsx') );
const NewHistoryProgramMbs                = lazy(() => import( 'views/Programmbs/New/NewHistory.jsx') );
const ShowPlanProgramMbs                  = lazy(() => import( 'views/Programmbs/Show/ShowPlan.jsx') );
const ShowProductProgramMbs               = lazy(() => import( 'views/Programmbs/Show/ShowProduct.jsx') );
const ShowProcessProgramMbs               = lazy(() => import( 'views/Programmbs/Show/ShowProcess.jsx') );
const ShowPriceProgramMbs                 = lazy(() => import( 'views/Programmbs/Show/ShowPrice.jsx') );
const ShowPromotionProgramMbs             = lazy(() => import( 'views/Programmbs/Show/ShowPromotion.jsx') );
const ShowPaperworkProgramMbs             = lazy(() => import( 'views/Programmbs/Show/ShowPaperwork.jsx') );
const ShowQualityProgramMbs               = lazy(() => import( 'views/Programmbs/Show/ShowQuality.jsx') );
const ShowServiceProgramMbs               = lazy(() => import( 'views/Programmbs/Show/ShowService.jsx') );
const ShowHistoryProgramMbs               = lazy(() => import( 'views/Programmbs/Show/ShowHistory.jsx') );
const EditProgramMbs                      = lazy(() => import( 'views/Programmbs/Edit/Show.jsx') );
const EditPlanProgramMbs                  = lazy(() => import( 'views/Programmbs/Edit/EditPlan.jsx') );
const EditProductProgramMbs               = lazy(() => import( 'views/Programmbs/Edit/EditProduct.jsx') );
const EditProcessProgramMbs               = lazy(() => import( 'views/Programmbs/Edit/EditProcess.jsx') );
const EditPriceProgramMbs                 = lazy(() => import( 'views/Programmbs/Edit/EditPrice.jsx') );
const EditPromotionProgramMbs             = lazy(() => import( 'views/Programmbs/Edit/EditPromotion.jsx') );
const EditPaperworkProgramMbs             = lazy(() => import( 'views/Programmbs/Edit/EditPaperwork.jsx') );
const EditQualityProgramMbs               = lazy(() => import( 'views/Programmbs/Edit/EditQuality.jsx') );
const EditServiceProgramMbs               = lazy(() => import( 'views/Programmbs/Edit/EditService.jsx') );
const EditHistoryProgramMbs               = lazy(() => import( 'views/Programmbs/Edit/EditHistory.jsx') );
const ProgramMbsFile                      = lazy(() => import( 'views/Programmbs/ShowFile/Show.jsx') );

//Programsa
const ProgramSa                           = lazy(() => import( 'views/Programsa/Show/Show.jsx') );
const NewProgramSa                        = lazy(() => import( 'views/Programsa/New/Show.jsx') );
const EditProgramSa                       = lazy(() => import( 'views/Programsa/Edit/Show.jsx') );
const EditMisionProgramSa                 = lazy(() => import( 'views/Programsa/Edit/EditMision.jsx') );
const EditGenerateProgramSa               = lazy(() => import( 'views/Programsa/Edit/EditGenerate.jsx') );
const EditFacilitateProgramSa             = lazy(() => import( 'views/Programsa/Edit/EditFacilitate.jsx') );
const EditGraduateProgramSa               = lazy(() => import( 'views/Programsa/Edit/EditGraduate.jsx') );
const EditSupportProgramSa                = lazy(() => import( 'views/Programsa/Edit/EditSupport.jsx') );
const EditPilotProgramSa                  = lazy(() => import( 'views/Programsa/Edit/EditPilot.jsx') );
const ProgramSaFile                       = lazy(() => import( 'views/Programsa/ShowFile/Show.jsx') );

//user
const UserEdit                            = lazy(() => import( 'views/User/Edit/Edit.jsx') );
const UserChangePassword                  = lazy(() => import( 'views/User/NewPassword/ChangePassword/ChangePassword.jsx') );
const newPassword                         = lazy(() => import( 'views/User/NewPassword/ChangePassword.jsx') );
const UserNewPassword                     = lazy(() => import( 'views/User/NewPassword/ChangePassword/ChangePassword.jsx') );
const EmailAccess                         = lazy(() => import( 'views/User/NewPassword/EmailAccessVerification/Index.jsx') );

const ProfileStep                         = lazy(() => import( 'views/User/AccountVerification/Steps/ProfileStep.jsx') );
const EmailStep                           = lazy(() => import( 'views/User/AccountVerification/Steps/EmailStep.jsx') );
const WhatsappStep                        = lazy(() => import( 'views/User/AccountVerification/Steps/WhatsappStep.jsx') );
const PhoneStep                           = lazy(() => import( 'views/User/AccountVerification/Steps/PhoneStep.jsx') );
const RevisionStep                        = lazy(() => import( 'views/User/AccountVerification/Steps/RevisionStep.jsx') );

//Profile
const Profile                             = lazy(() => import( 'views/Profile/Show/Show.jsx') );
const ProfileEdit                         = lazy(() => import( 'views/Profile/Edit/Edit.jsx') );
const ProfileEditPassword                 = lazy(() => import( 'views/Profile/ChangePassword/ChangePassword.jsx') );

//certificate
const CertificateGroup                    = lazy(() => import( 'views/Certificate/Group/Index.jsx') );
const CertificateList                     = lazy(() => import( 'views/Certificate/Student/Index.jsx') );

//Dashboard
const Dashboard                           = lazy(() => import( 'views/Dashboard/Index.jsx') );
const DashboardStudent                    = lazy(() => import( 'views/Dashboard/Student/Index.jsx') );
const AssignMentor                        = lazy(() => import( 'views/Dashboard/AssignMentor/Index.jsx') );
const ConfirmMentor                       = lazy(() => import( 'views/Dashboard/AssignMentor/Confirm.jsx') );
const ClearPendingDashboard               = lazy(() => import( 'views/Dashboard/AssignMentor/Clear.jsx') );
const CourseList                          = lazy(() => import( 'views/Dashboard/Course/CourseList/show.jsx') );
const CourseContent                       = lazy(() => import( 'views/Dashboard/Course/Content/Index.jsx') );
const PrincipleContent                    = lazy(() => import( 'views/Dashboard/Course/PrinciplesContent/Show.jsx') );
const PrincipleComment                    = lazy(() => import( 'views/Dashboard/Course/PrincipleComment/Index.jsx') );

const NsaGraduates                        = lazy(() => import( 'views/NsaGraduates/Index.jsx') );
const NsaGraduatesGroups                  = lazy(() => import( 'views/NsaGraduates/Groups/Index.jsx') );
const NsaGroupParticipants                = lazy(() => import( 'views/NsaGraduates/GroupParticipants/Index.jsx') );

//code
const ListCode                            = lazy(() => import( 'views/Code/Index.jsx') );
const ShowCode                            = lazy(() => import( 'views/Code/Show/Show.jsx') );
const EditCode                            = lazy(() => import( 'views/Code/Edit/Edit.jsx') );

//Administrator
const ListAdmin                           = lazy(() => import( 'views/Administrator/Index.jsx') );
const NewAdmin                            = lazy(() => import( 'views/Administrator/New/New.jsx') );
const ShowAdmin                           = lazy(() => import( 'views/Administrator/Show/Show.jsx') );
const EditAdmin                           = lazy(() => import( 'views/Administrator/Edit/Edit.jsx') );
const AdminPassword                       = lazy(() => import( 'views/Administrator/ChangePassword/ChangePassword.jsx') );

//Contract
const Contract                            = lazy(() => import( 'views/Contract/Index.jsx') );

//Register
const Register                            = lazy(() => import( 'views/Register/Index.jsx') );
const RegisterStudent                     = lazy(() => import( 'views/Register/Participants/New.jsx') );
const RegisterEvaluation                  = lazy(() => import( 'views/Register/PreEvaluation/New.jsx') );
const WaitingPage                         = lazy(() => import( 'views/Register/Participants/WaitingPage.jsx') );

//Transfer
const NewTransfer                         = lazy(() => import( 'views/Transfer/New/New.jsx') );
const ShowTransfer                        = lazy(() => import( 'views/Transfer/Show/Show.jsx') );
const EditTransfer                        = lazy(() => import( 'views/Transfer/Edit/Edit.jsx') );

//Grant
const Grant                               = lazy(() => import( 'views/Grant/Index.jsx') );
const ShowGrant                           = lazy(() => import( 'views/Grant/Show/Show.jsx') );
const InfoGrant                           = lazy(() => import( 'views/GrantAmbassador/Info/Info.jsx') );
const EditGrant                           = lazy(() => import( 'views/Grant/Edit/Edit.jsx') );
const NewGrant                            = lazy(() => import( 'views/Grant/New/New.jsx') );
const UpdateGrant                         = lazy(() => import( 'views/Grant/Update/Show.jsx') );
const GrantAmbassador                     = lazy(() => import( 'views/GrantAmbassador/Index.jsx') );
const NewGrantAmbassador                  = lazy(() => import( 'views/GrantAmbassador/New/New.jsx') );
const EditGrantAmbassador                 = lazy(() => import( 'views/GrantAmbassador/Edit/Edit.jsx') );
const ShowGrantAmbassador                 = lazy(() => import( 'views/GrantAmbassador/Show/Show.jsx') );
const ApplicationGrantAmbassador          = lazy(() => import( 'views/Grant/Application/Index.jsx') );
const ShowGrantStatisticGroup             = lazy(() => import( 'views/Grant/IndexTabs/StatisticTab/Groups/Index.jsx') );

//Requirement
const NewWellness                         = lazy(() => import( 'views/Requirement/Wellness/New/Index.jsx') );
const EditWellness                        = lazy(() => import( 'views/Requirement/Wellness/Edit/Index.jsx') );
const WellnessFile                        = lazy( () => import( 'views/Requirement/Wellness/ShowFile/Show.jsx') );

// Mbs Requirement
const NewMbs                              = lazy(() => import( 'views/Requirement/Mbs/New/Index.jsx') );
const EditMbs                             = lazy(() => import( 'views/Requirement/Mbs/Edit/Index.jsx') );

//Sa Requirement
const NewProgramSaReq                     = lazy(() => import( 'views/Requirement/SuccessAmbassador/New/Index.jsx') );
const EditProgramSaReq                    = lazy(() => import( 'views/Requirement/SuccessAmbassador/Edit/Index.jsx') );

// Express Requirement
const NewExpress                          = lazy( () => import( 'views/Requirement/Express/New/Index.jsx') );
const EditExpress                         = lazy( () => import( 'views/Requirement/Express/Edit/Index.jsx') );
const ExpressFile                         = lazy( () => import( 'views/Requirement/Express/ShowFile/Show.jsx' ));

//Evaluation
const NewPreevaluation                    = lazy(() => import( 'views/Evaluation/PreEvaluation/New.jsx') );
const NewWellPreevaluation                = lazy( () => import( 'views/Evaluation/WellPreEvaluation/New.jsx') );
const FollowUpEvaluation                 = lazy( () => import( 'views/Evaluation/Mbs/Index.jsx' ));

//EditProfile
const ProfileUpdate                       = lazy(() => import( 'views/Dashboard/ProfileUpdate/Index.jsx') );

//Resource
const ListCategory                        = lazy(() => import( 'views/Resource/Ambassador/Categoty/show.jsx') );
const ListSubcategory                     = lazy(() => import( 'views/Resource/Ambassador/Subcategory/show.jsx') );
const ListResource                        = lazy(() => import( 'views/Resource/Ambassador/Resource/show.jsx') );

//History
const NewHistory                          = lazy(() => import( 'views/History/New/New.jsx') );
const EditHistory                         = lazy(() => import( 'views/History/Edit/Edit.jsx') );

//Tutorial
const ListSection                         = lazy(() => import( 'views/Tutorial/User/Section/Index.jsx') );
const UserTutorial                        = lazy(() => import( 'views/Tutorial/User/Tutorial/Index.jsx') );
//Checkout
const Checkout                            = lazy(() => import( 'views/Checkout/Index.jsx') );
const SuccessPayment                      = lazy(() => import( 'views/Checkout/Success/Index.jsx') );

//Recognition
const RecognitionList                     = lazy(() => import('views/Recognition/List/Show.jsx'));

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router history={history}>
        <Suspense fallback={<LoadingAlert />}>
          <Switch>
            <PrivateRoute path="/rtl" component={RtlLayout} />
            <PrivateRoute path="/auth" component={AuthLayout} />
            <PrivateRoute path="/table" component={ReactTable} />
            <PrivateRoute path="/age" component={Age} />
            <Route path="/login" component={Login} exact />
            <Route path="/login/:lang" component={Login} exact />
            <PrivateRoute path="/app" component={App} />  
            <PrivateRoute path="/student" component={Student} exact />
            <PrivateRoute path="/student/new/:id" component={NewStudent} exact />
            <PrivateRoute path="/student/show/:id" component={ShowStudent} exact />
            <PrivateRoute path="/student/edit/:id" component={EditStudent} exact />
            <PrivateRoute path="/student/preevaluation" component={PreEvaluationStudent} exact />
            <PrivateRoute path="/student/postevaluation" component={PostEvaluationStudent} exact />
            <PrivateRoute path="/student/editpassword/:id" component={StudentChangePassword} exact />
            <PrivateRoute path="/course/new" component={CourseNew} />
            <PrivateRoute path="/course/list" component={CourseList} exact />
            <PrivateRoute path="/course/content/:id" component={CourseContent} exact />

            <PrivateRoute path="/course/requirement/wellness/ambassadorskills/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/pilotgroup/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/futureplans/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/conclusions/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/postevaluation/new/:tab" component={NewWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/ambassadorskills/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/pilotgroup/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/futureplans/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/conclusions/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/course/requirement/wellness/postevaluation/edit/:tab/:id" component={EditWellness} exact />
            <PrivateRoute path="/requirement/wellness/showfile/:id" component={WellnessFile} exact />

            <PrivateRoute path="/course/requirement/programsa/mission/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/generategroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/facilitegroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/graduategroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/supportgroups/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/pilotgroup/new/:tab" component={NewProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/mission/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/generategroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/facilitegroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/graduategroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/supportgroups/edit/:tab/:id" component={EditProgramSaReq} exact />
            <PrivateRoute path="/course/requirement/programsa/pilotgroup/edit/:tab/:id" component={EditProgramSaReq} exact />

            <PrivateRoute path="/course/requirement/mbs/business/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/qualitylife/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/moneymanagement/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomestatement/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomeexpenselog/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/businessbudget/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/successstory/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/checklist/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/postevaluation/new/:tab" component={NewMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/service/new/:tab" component={NewMbs} exact />

            <PrivateRoute path="/course/requirement/mbs/business/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/qualitylife/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/moneymanagement/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomestatement/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/incomeexpenselog/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/businessbudget/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/successstory/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/checklist/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/postevaluation/edit/:tab/:id" component={EditMbs} exact />
            <PrivateRoute path="/course/requirement/mbs/service/edit/:tab/:id" component={EditMbs} exact />

            <PrivateRoute path="/course/requirement/express/new/:tab" component={NewExpress} exact />
            <PrivateRoute path="/course/requirement/express/edit/:tab/:id" component={EditExpress} exact />
            <PrivateRoute path="/requirement/express/showfile/:id" component={ExpressFile} exact />

            <PrivateRoute path="/principle/content/:id" component={PrincipleContent} />
            <PrivateRoute path="/programmbs" component={IndexProgramMbs} exact />
            <PrivateRoute path="/programmbs/new" component={NewProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/plan" component={NewPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/product" component={NewProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/process" component={NewProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/price" component={NewPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/promotion" component={NewPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/paperwork" component={NewPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/quality" component={NewQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/service" component={NewServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/new/history" component={NewHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/:id" component={ProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/plan/:id" component={ShowPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/product/:id" component={ShowProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/process/:id" component={ShowProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/price/:id" component={ShowPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/promotion/:id" component={ShowPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/paperwork/:id" component={ShowPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/quality/:id" component={ShowQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/service/:id" component={ShowServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/show/history/:id" component={ShowHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/:id" component={EditProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/plan/:id" component={EditPlanProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/product/:id" component={EditProductProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/process/:id" component={EditProcessProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/price/:id" component={EditPriceProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/promotion/:id" component={EditPromotionProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/paperwork/:id" component={EditPaperworkProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/quality/:id" component={EditQualityProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/service/:id" component={EditServiceProgramMbs} exact />
            <PrivateRoute path="/programmbs/edit/history/:id" component={EditHistoryProgramMbs} exact />
            <PrivateRoute path="/programmbs/showfile/:id" component={ProgramMbsFile} exact />
            <PrivateRoute path="/programsa/show/:id" component={ProgramSa} exact />
            <PrivateRoute path="/programsa/new" component={NewProgramSa} exact />
            <PrivateRoute path="/programsa/edit/:id" component={EditProgramSa} exact />
            <PrivateRoute path="/programsa/edit/mision/:id" component={EditMisionProgramSa} exact />
            <PrivateRoute path="/programsa/edit/generate/:id" component={EditGenerateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/facilitate/:id" component={EditFacilitateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/graduate/:id" component={EditGraduateProgramSa} exact />
            <PrivateRoute path="/programsa/edit/support/:id" component={EditSupportProgramSa} exact />
            <PrivateRoute path="/programsa/edit/pilot/:id" component={EditPilotProgramSa} exact />
            <PrivateRoute path="/programsa/showfile/:id" component={ProgramSaFile} exact />
            <PrivateRoute path="/user/newpassword/:id" component={UserNewPassword} exact />
            <PrivateRoute path="/user/edit/:id" component={UserEdit} exact />
            <PrivateRoute path="/user/accountverification/steps/profile" component={ProfileStep} exact />
            <PrivateRoute path="/user/accountverification/steps/email" component={EmailStep} exact />
            <PrivateRoute path="/user/accountverification/steps/whatsapp" component={WhatsappStep} exact />
            <PrivateRoute path="/user/accountverification/steps/phone" component={PhoneStep} exact />
            <PrivateRoute path="/user/accountverification/steps/revision" component={RevisionStep} exact />
            <Route path="/user/editpassword/:id" component={UserChangePassword} exact />
            <PrivateRoute path="/profile" component={Profile} exact />
            <PrivateRoute path="/profile/edit" component={ProfileEdit} exact />
            <PrivateRoute path="/profile/editpassword" component={ProfileEditPassword} exact />
            <PrivateRoute path="/group" component={Group} exact />
            <PrivateRoute path="/group/new/:id" component={NewGroup} exact />
            <PrivateRoute path="/group/show/:id" component={ShowGroup} exact />
            <PrivateRoute path="/group/edit/:id" component={EditGroup} exact />
            <PrivateRoute path="/group/ambassador" component={GroupAmbassadors} exact />
            <PrivateRoute path="/group/progress/:id" component={ProjectProgress} exact />
            <PrivateRoute path="/group/student/:id" component={StudentGroup} exact />
            <PrivateRoute path="/group/uploadImage/:id" component={UploadImage} exact />
            <PrivateRoute path="/ambassadorstudent" component={AmbassadorStudent} exact />
            <PrivateRoute path="/ambassador" component={Ambassador} exact />
            <PrivateRoute path="/ambassador/show/:id" component={ShowAmbassador} exact />
            <PrivateRoute path="/ambassador/edit/:id" component={EditAmbassador} exact />
            <PrivateRoute path="/ambassador/new" component={NewAmbassador} exact />
            <PrivateRoute path="/ambassador/editpassword/:id" component={AmbassadorChangePassword} exact />
            <PrivateRoute path="/certificate" component={CertificateGroup} exact />
            <PrivateRoute path="/certificate/list/student/:id" component={CertificateList} exact />
            <PrivateRoute path="/dashboard" component={Dashboard} exact />
            <PrivateRoute path="/dashboard/student" component={DashboardStudent} exact />
            <PrivateRoute path="/dashboard/assignmentor/:student" component={AssignMentor} exact />
            <PrivateRoute path="/dashboard/confirmmentor/:student/:group" component={ConfirmMentor} exact />
            <PrivateRoute path="/dashboard/clearpending/:student" component={ClearPendingDashboard} exact />
            <PrivateRoute path="/nsagraduates/:id" component={NsaGraduates} exact />
            <PrivateRoute path="/nsagraduates/groups/:id" component={NsaGraduatesGroups} exact />
            <PrivateRoute path="/nsagraduates/groupparticipants/:group/:user" component={NsaGroupParticipants} exact />
            <PrivateRoute path="/ambassadorstudent/assignmentor/:student" component={AmbassadorStudentAssignMentor} exact />
            <PrivateRoute path="/ambassadorstudent/confirmmentor/:student/:group" component={AmbassadorStudentConfirmMentor} exact />
            <PrivateRoute path="/report" component={Reports} exact />
            <PrivateRoute path="/code" component={ListCode} exact />
            <PrivateRoute path="/code/show/:id" component={ShowCode} exact />
            <PrivateRoute path="/code/edit/:id" component={EditCode} exact />
            <PrivateRoute path="/admin" component={ListAdmin} exact />
            <PrivateRoute path="/admin/new" component={NewAdmin} exact />
            <PrivateRoute path="/admin/show/:id" component={ShowAdmin} exact />
            <PrivateRoute path="/admin/edit/:id" component={EditAdmin} exact />
            <PrivateRoute path="/admin/editpassword/:id" component={AdminPassword} exact />
            <Route path="/register" component={Register} exact />
            <Route path="/register/:lang" component={Register} exact />
            <Route path="/register/new/:id" component={RegisterStudent} exact />
            <Route path="/register/participants/waitingpage" component={WaitingPage} exact />
            <PrivateRoute path="/registration/evaluation" component={RegisterEvaluation} exact />
            <Route path="/password" component={newPassword} exact />
            <Route path="/password/emailaccessverification" component={EmailAccess} exact />
            <PrivateRoute path="/grant" component={Grant} exact />
            <PrivateRoute path="/grant/new" component={NewGrant} exact />
            <PrivateRoute path="/grant/info/:id" component={InfoGrant} exact />
            <PrivateRoute path="/grant/show/:id" component={ShowGrant} exact />
            <PrivateRoute path="/grant/edit/:id" component={EditGrant} exact />
            <PrivateRoute path="/grant/update/:id/:grant" component={UpdateGrant} exact />
            <PrivateRoute path="/grant/ambassador" component={GrantAmbassador} exact />
            <PrivateRoute path="/grant/newambassador/:id" component={NewGrantAmbassador} exact />
            <PrivateRoute path="/grant/editambassador/:id/:ambassador" component={EditGrantAmbassador} exact />
            <PrivateRoute path="/grant/showambassador/:id/:ambassador" component={ShowGrantAmbassador} exact />
            <PrivateRoute path="/grant/application/:id" component={ApplicationGrantAmbassador} exact />
            <PrivateRoute path="/grant/statistic/group/:id" component={ShowGrantStatisticGroup} exact />
            <PrivateRoute path="/transfer/show/:id" component={ShowTransfer} exact />
            <PrivateRoute path="/transfer/edit/:id" component={EditTransfer} exact />
            <PrivateRoute path="/transfer/new" component={NewTransfer} exact />
            <PrivateRoute path="/contract" component={Contract} exact />
            <PrivateRoute path="/evaluation/preevaluation/:id" component={NewPreevaluation} exact />
            <PrivateRoute path="/evaluation/wellpreevaluation/:id" component={NewWellPreevaluation} exact />
            <Route path="/evaluation/mbs/followup/:id" component={FollowUpEvaluation} exact />
            <PrivateRoute path="/dashboard/profileupdate" component={ProfileUpdate} exact />
            <PrivateRoute path="/resource/ambassador/category" component={ListCategory} exact />
            <PrivateRoute path="/resource/ambassador/subcategory/:id" component={ListSubcategory} exact />
            <PrivateRoute path="/resource/ambassador/resource/:id" component={ListResource} exact />
            <PrivateRoute path="/dashboard/course/principlecomment/:id" component={PrincipleComment} exact />
            <PrivateRoute path="/history/new" component={NewHistory} exact />
            <PrivateRoute path="/history/edit/:id" component={EditHistory} exact />
            <PrivateRoute path="/tutorial/user/section" component={ListSection} exact />
            <PrivateRoute path="/tutorial/user/tutorial/:id" component={UserTutorial} exact />
            <PrivateRoute path="/checkout/:id" component={Checkout} exact />
            <PrivateRoute path="/checkout/payment/success/:id" component={SuccessPayment} exact />
            <PrivateRoute path="/checkout/payment/success/:course/:user/:payment" component={SuccessPayment} exact />
            <PrivateRoute path="/recognition/list" component={RecognitionList} exact />
            <PrivateRoute path="/welcomepackage" component={WelcomePackage} exact />
            <Redirect from="/" to="/login" />
          </Switch>
        </Suspense>
      </Router>,
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
