import { AMBASSADOR_LIST } from "constants/actionTypes";
import { SHOW_AMBASSADOR } from "constants/actionTypes";
import { LOAD_FORM_AMBASSADOR } from "constants/actionTypes";
import { LOADING_AMBASSADOR } from "constants/actionTypes";
import { PAGINATED_AMBASSADOR_GALLERY } from "constants/actionTypes";
import { AMBASSADOR_RECOGNITION_LEVEL } from "constants/actionTypes";
import { GALLERY_LOADING } from "constants/actionTypes";
import { GET_AMBASSADOR_STATE } from "constants/actionTypes";
import { PAGINATED_AMBASSADOR_LIST } from "constants/actionTypes";
import { EDIT_AMBASSADOR, ERROR_EDIT_AMBASSADOR } from "constants/actionTypes";
import { NEW_AMBASSADOR } from "constants/actionTypes";
import { EDIT_PASSWORD_AMBASSADOR } from "constants/actionTypes";
import { DELETE_AMBASSADOR } from "constants/actionTypes";


const initialState = {
  ambassador_list: [],
  ambassador_state: [],
  loading: true,
  editError: false,
  show_ambassador: {
    email: "",
    username: "",
    language: "",
    language_grader: "",
    first_name: "",
    last_name: "",
    country: "",
    areacode: "USA +1",
  },
  paginated_ambassador_list: [],
  ambassador_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "ambassador_name",
    order_direction: "ASC",
  },
  paginated_ambassador_gallery: [],
  ambassador_gallery_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 8,
    order_column: "ambassador_name",
    order_direction: "ASC",
  },
  pages_total: "1",
  search_loading: false,
  gallery_pages_total: "1",
  gallery_loading: false,
  ambassador_recognition_level: [],
}

export const ambassadorReducer = (state = initialState, action) => {
  switch (action.type) {
    case AMBASSADOR_LIST:
      return Object.assign({}, state, {
        ambassador_list: action.payload,
        loading: false
      });
    case SHOW_AMBASSADOR:
      return Object.assign({}, state, {
        show_ambassador: action.payload
      });
    case LOAD_FORM_AMBASSADOR:
      return Object.assign({}, state, {
        data: action.data
      });
    case EDIT_AMBASSADOR:
      return Object.assign({}, state, {
        edit_ambassador: action.payload
      });
    case ERROR_EDIT_AMBASSADOR:
      return Object.assign({}, state, {
        editError: true
      })
    case NEW_AMBASSADOR:
      return Object.assign({}, state, {
        new_ambassador: action.payload
      });
    case EDIT_PASSWORD_AMBASSADOR:
      return Object.assign({}, state, {
        edit_password: action.payload
      });
    case DELETE_AMBASSADOR:
      return Object.assign({}, state, {
        delete_ambassador: action.payload
      });

    case PAGINATED_AMBASSADOR_LIST:
      return Object.assign({}, state, {
        paginated_ambassador_list: action.payload.list,
        pages_total: action.payload.pages_total,
        loading: false
      });
    case GET_AMBASSADOR_STATE:
      return Object.assign({}, state, {
        ambassador_state: action.payload
      });
    case LOADING_AMBASSADOR:
      return Object.assign({}, state, {
        loading: true
      });
    case PAGINATED_AMBASSADOR_GALLERY:
      return Object.assign({}, state, {
        paginated_ambassador_gallery: action.payload.list,
        gallery_pages_total: action.payload.pages_total,
        gallery_loading: false
      });
    case GALLERY_LOADING:
      return Object.assign({}, state, {
        gallery_loading: action.payload
      });
    case AMBASSADOR_RECOGNITION_LEVEL:
      return Object.assign({}, state, {
        ambassador_recognition_level: action.payload
      });
  }
  return state;
}