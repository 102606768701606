import { AMBASSADOR_LIST, SHOW_AMBASSADOR, LOAD_FORM_AMBASSADOR } from 'constants/actionTypes.jsx';
import { EDIT_AMBASSADOR, ERROR_EDIT_AMBASSADOR, SUCCESSFULL_EDIT } from 'constants/actionTypes';
import { NEW_AMBASSADOR, SUCCESSFULL_REDIRECT } from 'constants/actionTypes';
import { EDIT_PASSWORD_AMBASSADOR } from 'constants/actionTypes';
import { DELETE_AMBASSADOR, SUCCESSFUL_DELETE, SUCCESSFULL_NEW } from 'constants/actionTypes';
import { AMBASSADOR_CONTRACT } from 'constants/actionTypes';
import { DELETE_SUCCESSFUL } from 'constants/actionTypes';
import { PAGINATED_AMBASSADOR_LIST } from 'constants/actionTypes';
import { CLEAN_LOADING_SEARCH } from 'constants/actionTypes';
import { LOADING_AMBASSADOR } from 'constants/actionTypes';
import history from '../history';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { GET_AMBASSADOR_STATE } from 'constants/actionTypes';
import { PAGINATED_AMBASSADOR_GALLERY } from 'constants/actionTypes';
import { GALLERY_LOADING } from 'constants/actionTypes';
import { AMBASSADOR_RECOGNITION_LEVEL } from 'constants/actionTypes';
import { AMBASSADOR_LEVEL } from 'constants/actionTypes';

export const getAmbassadorList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AMBASSADOR_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getAmbassadorPaginationList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("keyword", reduxState.ambassadorReducer.ambassador_pagination_params.keyword);
        urlencoded.append("page", reduxState.ambassadorReducer.ambassador_pagination_params.page);
        urlencoded.append("page_size", reduxState.ambassadorReducer.ambassador_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.ambassadorReducer.ambassador_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.ambassadorReducer.ambassador_pagination_params.order_direction);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/pagination?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: PAGINATED_AMBASSADOR_LIST, payload: json.data });
                    dispatch({ type: CLEAN_LOADING_SEARCH });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getContractAmbassadorList = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/contract", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AMBASSADOR_LIST, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showAmbassador = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/show/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: SHOW_AMBASSADOR, payload: json.data });
                    dispatch({ type: LOAD_FORM_AMBASSADOR, data: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getContract = key => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/contract/ambassador/" + reduxState.loginReducer.active_user.language + "/" + reduxState.loginReducer.active_user.id + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AMBASSADOR_CONTRACT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const loadFormAmbassador = data => ({ type: LOAD_FORM_AMBASSADOR, data });

export const editAmbassador = () => {
    return (dispatch, getState) => {

        const reduxState = getState();
        const key = reduxState.form.ambassadorform.values.id;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("username", reduxState.form.ambassadorform.values.username);
        urlencoded.append("firstName", reduxState.form.ambassadorform.values.first_name);
        urlencoded.append("lastName", reduxState.form.ambassadorform.values.last_name);
        urlencoded.append("language", reduxState.form.ambassadorform.values.language);
        urlencoded.append("country", reduxState.form.ambassadorform.values.country);
        urlencoded.append("city", reduxState.form.ambassadorform.values.city);
        urlencoded.append("whatsapp", reduxState.form.ambassadorform.values.whatsapp);
        urlencoded.append("code", reduxState.form.ambassadorform.values.code);
        urlencoded.append("picture", reduxState.form.ambassadorform.values.picture);
        urlencoded.append("areacode", reduxState.form.ambassadorform.values.areacode);
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/ambassador/edit/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                dispatch({ type: ERROR_EDIT_AMBASSADOR })
            })
    }
};

export const newAmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("username", reduxState.form.ambassadorNewform.values.username);
        urlencoded.append("firstName", reduxState.form.ambassadorNewform.values.first_name);
        urlencoded.append("lastName", reduxState.form.ambassadorNewform.values.last_name);
        urlencoded.append("language", reduxState.form.ambassadorNewform.values.language);
        urlencoded.append("country", reduxState.form.ambassadorNewform.values.country);
        urlencoded.append("city", reduxState.form.ambassadorNewform.values.city);
        urlencoded.append("whatsapp", reduxState.form.ambassadorNewform.values.whatsapp);
        urlencoded.append("code", reduxState.form.ambassadorNewform.values.code);
        urlencoded.append("password", reduxState.form.ambassadorNewform.values.password);
        urlencoded.append("areacode", reduxState.form.ambassadorNewform.values.areacode);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/new", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: NEW_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFULL_NEW });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const editPassword = (params, key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("password", params.ambassadorPassword);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/editpassword/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: EDIT_PASSWORD_AMBASSADOR, payload: json });
                    dispatch({ type: SUCCESSFULL_EDIT });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const deleteAmbassador = (key, redirect) => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/delete/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DELETE_AMBASSADOR, payload: json.data });
                    dispatch({ type: SUCCESSFUL_DELETE });
                    dispatch({ type: DELETE_SUCCESSFUL });
                    redirect.push('/ambassador');
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const showAmbassadorRedirect = (redirect) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        const key = reduxState.ambassadorReducer.new_ambassador.id
        dispatch({ type: SUCCESSFULL_REDIRECT });
        return redirect.push('/ambassador/show/' + key);
    }
}

export const loadingAmbassador = () => ({ type: LOADING_AMBASSADOR })

export const getAmbassadorState = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/state/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_AMBASSADOR_STATE, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getAmbassadorPaginationCountry = (country) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("keyword", reduxState.ambassadorReducer.ambassador_gallery_pagination_params.keyword);
        urlencoded.append("page", reduxState.ambassadorReducer.ambassador_gallery_pagination_params.page);
        urlencoded.append("page_size", reduxState.ambassadorReducer.ambassador_gallery_pagination_params.page_size);
        urlencoded.append("order_column", reduxState.ambassadorReducer.ambassador_gallery_pagination_params.order_column);
        urlencoded.append("order_direction", reduxState.ambassadorReducer.ambassador_gallery_pagination_params.order_direction);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/pagination/country/" + country + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                } else {
                    dispatch({ type: PAGINATED_AMBASSADOR_GALLERY, payload: json.data });
                }
            })
    }
}

export const galleryLoading = (key) => ({ type: GALLERY_LOADING, payload: key });

export const getAmbassadorRecognitionLevel = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/recognitionlevel/" + key, requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    const level = json.data.recognition.level;
                    dispatch({ type: AMBASSADOR_RECOGNITION_LEVEL, payload: json.data });
                    dispatch({ type: AMBASSADOR_LEVEL, payload: level });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}
